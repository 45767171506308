.intro-overlay {
  z-index: 9999;
  background: #000000b3;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.intro-content {
  text-align: center;
  color: #000;
  background: #fff;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  max-height: 90%;
  padding: 2em;
  position: relative;
  overflow-y: auto;
}

.intro-content h2 {
  color: #000;
  margin-bottom: 1em;
  font-size: 1.8em;
}

.intro-content p {
  color: #000;
  margin-bottom: 1.5em;
  font-size: 1em;
}

.privacy-policy-overlay {
  z-index: 9999;
  background: #000000b3;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.privacy-policy-content {
  text-align: left;
  color: #000;
  background: #fff;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  max-height: 90%;
  padding: 2em;
  position: relative;
  overflow-y: auto;
}

.privacy-policy-content h2 {
  color: #000;
  margin-bottom: 1em;
  font-size: 1.8em;
}

.privacy-policy-content p {
  color: #000;
  margin-bottom: 1.5em;
  font-size: 1em;
}

.terms-conditions-overlay {
  z-index: 9999;
  background: #000000b3;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.terms-conditions-content {
  text-align: left;
  color: #000;
  background: #fff;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  max-height: 90%;
  padding: 2em;
  position: relative;
  overflow-y: auto;
}

.terms-conditions-content h2 {
  color: #000;
  margin-bottom: 1em;
  font-size: 1.8em;
}

.terms-conditions-content p {
  color: #000;
  margin-bottom: 1.5em;
  font-size: 1em;
}

.close-button {
  color: #000;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1.5em;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button:hover {
  color: #ff7f3e;
}

@media (width <= 480px) {
  .intro-content, .privacy-policy-content, .terms-conditions-content {
    width: 90%;
    padding: 1.5em;
    font-size: .9em;
  }
}

@media (width >= 768px) {
  .intro-content, .privacy-policy-content, .terms-conditions-content {
    width: 60%;
    max-width: 800px;
    padding: 3em;
  }
}
/*# sourceMappingURL=index.561854cd.css.map */
