/* Intro Overlay Styling */
.intro-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    align-items: center;
    justify-content: center;
    z-index: 9999;
    overflow-y: auto;
}

.intro-content {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 2em;
    max-width: 90%;
    width: 400px; /* Default width for smaller screens */
    max-height: 90%;
    overflow-y: auto;
    text-align: center;
    position: relative;
    color: #000; /* Set text color to black */
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5em;
    color: #000; /* Set close button color to black */
    cursor: pointer;
}

.close-button:hover {
    color: #FF7F3E;
}

.intro-content h2 {
    margin-bottom: 1em;
    font-size: 1.8em;
    color: #000; /* Set heading color to black */
}

.intro-content p {
    margin-bottom: 1.5em;
    font-size: 1em;
    color: #000; /* Set paragraph text color to black */
}


/* Privacy Policy Overlay Styling */
.privacy-policy-overlay {
    display: none; /* Initially hidden */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    align-items: center;
    justify-content: center;
    z-index: 9999;
    overflow-y: auto;
}

.privacy-policy-content {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 2em;
    max-width: 90%;
    width: 400px; /* Default width for smaller screens */
    max-height: 90%;
    overflow-y: auto;
    text-align: left;
    position: relative;
    color: #000; /* Set text color to black */
}

.privacy-policy-content h2 {
    margin-bottom: 1em;
    font-size: 1.8em;
    color: #000; /* Set heading color to black */
}

.privacy-policy-content p {
    margin-bottom: 1.5em;
    font-size: 1em;
    color: #000; /* Set paragraph text color to black */
}

/* Same Close Button Styling */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5em;
    color: #000;
    cursor: pointer;
}

.close-button:hover {
    color: #FF7F3E;
}


/* Terms and Conditions Overlay Styling */
.terms-conditions-overlay {
    display: none; /* Initially hidden */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    align-items: center;
    justify-content: center;
    z-index: 9999;
    overflow-y: auto;
}

.terms-conditions-content {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 2em;
    max-width: 90%;
    width: 400px; /* Default width for smaller screens */
    max-height: 90%;
    overflow-y: auto;
    text-align: left; /* Same as Privacy Policy */
    position: relative;
    color: #000; /* Set text color to black */
}

.terms-conditions-content h2 {
    margin-bottom: 1em;
    font-size: 1.8em;
    color: #000; /* Set heading color to black */
}

.terms-conditions-content p {
    margin-bottom: 1.5em;
    font-size: 1em;
    color: #000; /* Set paragraph text color to black */
}

/* Same Close Button Styling */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5em;
    color: #000; /* Set close button color to black */
    cursor: pointer;
}

.close-button:hover {
    color: #FF7F3E; /* Change color on hover */
}

/* Media Query for Smaller Screens (Mobile) */
@media (max-width: 480px) {
    .intro-content {
        width: 90%; /* Ensure it fits within smaller screens */
        padding: 1.5em;
        font-size: 0.9em;
    }

    .privacy-policy-content {
        width: 90%;
        padding: 1.5em;
        font-size: 0.9em;
    }

    .terms-conditions-content {
        width: 90%; /* Ensure it fits within smaller screens */
        padding: 1.5em;
        font-size: 0.9em;
    }
}

/* Media Query for Larger Screens (Desktops) */
@media (min-width: 768px) {
    .intro-content {
        width: 60%; /* Increase the width for desktops */
        max-width: 800px; /* Set a maximum width */
        padding: 3em;
    }

    .privacy-policy-content {
        width: 60%;
        max-width: 800px;
        padding: 3em;
    }

    .terms-conditions-content {
        width: 60%; /* Increase the width for desktops */
        max-width: 800px; /* Set a maximum width */
        padding: 3em;
    }
}